import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85')
];

export const server_loads = [];

export const dictionary = {
		"/accept-invite": [~75],
		"/complete-setup": [76],
		"/error": [77],
		"/healthz": [78],
		"/login": [79],
		"/login/error": [80],
		"/onboarding": [81],
		"/signup-confirmation": [83],
		"/signup-retry": [84],
		"/signup": [82],
		"/switch/[org]": [85],
		"/[org]": [17,[2],[3]],
		"/[org]/apps": [18,[2],[3]],
		"/[org]/apps/new": [21,[2],[3]],
		"/[org]/apps/playground": [22,[2,5],[3]],
		"/[org]/apps/private/[id]": [23,[2],[3]],
		"/[org]/apps/private/[id]/[version]": [24,[2,6],[3]],
		"/[org]/apps/private/[id]/[version]/events": [25,[2,6],[3]],
		"/[org]/apps/private/[id]/[version]/logs": [26,[2,6],[3]],
		"/[org]/apps/[id]": [19,[2,4],[3]],
		"/[org]/apps/[id]/credentials": [20,[2,4],[3]],
		"/[org]/deploy": [27,[2],[3]],
		"/[org]/deploy/[recipeId]": [28,[2],[3]],
		"/[org]/governance": [29,[2],[3]],
		"/[org]/governance/classifications": [30,[2],[3]],
		"/[org]/governance/classifications/[classificationId]/[[valueId]]": [31,[2],[3]],
		"/[org]/governance/labels": [32,[2],[3]],
		"/[org]/governance/labels/[labelId]": [33,[2],[3]],
		"/[org]/governance/ownership": [34,[2],[3]],
		"/[org]/governance/ownership/[typeId]": [35,[2],[3]],
		"/[org]/governance/products-features": [36,[2],[3]],
		"/[org]/governance/products-features/[itemId]": [37,[2],[3]],
		"/[org]/import": [38,[2,7],[3]],
		"/[org]/import/private/[privateAppId]": [41,[2,7],[3]],
		"/[org]/import/private/[privateAppId]/new": [42,[2],[3]],
		"/[org]/import/[appId]": [39,[2,7],[3]],
		"/[org]/import/[appId]/new": [40,[2],[3]],
		"/[org]/inbox/[[messageId]]": [43,[2],[3]],
		"/[org]/link/github-app": [44],
		"/[org]/people": [45,[2],[3]],
		"/[org]/people/invite": [47,[2],[3]],
		"/[org]/people/my-profile": [48,[2],[3]],
		"/[org]/people/pending": [49,[2],[3]],
		"/[org]/people/[personId]": [46,[2],[3]],
		"/[org]/projects": [50,[2],[3]],
		"/[org]/projects/[projectId]/(subpages)": [51,[2,8,9],[3]],
		"/[org]/projects/[projectId]/(subpages)/deployments": [52,[2,8,9],[3]],
		"/[org]/projects/[projectId]/(subpages)/deployments/[deploymentId]": [53,[2,8,10],[3]],
		"/[org]/projects/[projectId]/(subpages)/deployments/[deploymentId]/logs": [54,[2,8,10],[3]],
		"/[org]/projects/[projectId]/(subpages)/edit": [55,[2,8,11],[3]],
		"/[org]/projects/[projectId]/(subpages)/logs": [56,[2,8,9],[3]],
		"/[org]/projects/[projectId]/(subpages)/resources": [57,[2,8,9],[3]],
		"/[org]/projects/[projectId]/(subpages)/variables": [58,[2,8,9],[3]],
		"/[org]/recipes": [59,[2],[3]],
		"/[org]/recipes/application/[recipeId]/display": [61,[2,12],[3]],
		"/[org]/recipes/application/[recipeId]/variables": [62,[2,12],[3]],
		"/[org]/recipes/application/[recipeId]/[[stepId]]": [60,[2,12,13],[3]],
		"/[org]/recipes/workflow/[recipeId]/display": [64,[2,14],[3]],
		"/[org]/recipes/workflow/[recipeId]/[[stepId]]": [63,[2,14],[3]],
		"/[org]/resources": [65,[2],[3]],
		"/[org]/resources/[resourceId]": [66,[2,15],[3]],
		"/[org]/resources/[resourceId]/logs": [67,[2,15],[3]],
		"/[org]/settings": [68,[2,16],[3]],
		"/[org]/settings/business/billing": [69,[2,16],[3]],
		"/[org]/settings/business/single-sign-on": [70,[2,16],[3]],
		"/[org]/settings/developer/api-keys": [71,[2,16],[3]],
		"/[org]/teams": [72,[2],[3]],
		"/[org]/teams/create": [74,[2],[3]],
		"/[org]/teams/[teamId]": [73,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';